<template>
  <div>
    <div class="page-left">
      <span>共 {{ total }} 项记录</span>
      <Select v-model="size" style="display: inline-block" size="small" @on-change="changeSize">
        <Option v-for="item in selectSize" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
    </div>
    <div class="page-right">
      <Page
        :page-size-opts="[10, 20, 40, 100]"
        :total="total"
        :page-size="size"
        size="small"
        :current.sync="page"
        show-elevator
        @on-change="changePage"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonPage',
  props: ['total'],
  data() {
    return {
      selectSize: [
        {
          value: 10,
          label: '每页10项'
        },
        {
          value: 20,
          label: '每页20项'
        },
        // {
        //   value: 30,
        //   label: '每页30项'
        // },
        {
          value: 40,
          label: '每页40项'
        },
        {
          value: 100,
          label: '每页100项'
        }
      ],
      size: 10,
      page: 1
    }
  },
  methods: {
    changePage(val) {
      this.$emit('changePage', val)
    },
    changeSize(val) {
      this.size = val
      this.$emit('changeSize', val)
    },
    resetPage() {
      this.page = 1
    }
  }
}
</script>
<style scoped lang="less">
.page-left {
  display: inline-block;
  float: left;
  /deep/ .ivu-select {
    width: 100px;
    margin-left: 6px;
  }
}
.page-right {
  display: inline-block;
  float: right;
  /deep/.ivu-page {
    .ivu-page-prev {
      background-color: transparent;
    }
    .ivu-page-next {
      background-color: transparent;
    }
    .ivu-page-item {
      background-color: transparent;
    }
    .ivu-page-item-active {
      background-color: #fff;
    }
  }
}
</style>
