<template>
  <div>
    <Row class="expand-row">
      <Col span="2">开始时间:</Col>
      <Col span="22">{{ $formatTime(row.startTime, 'yyyy-MM-dd hh:mm') }}</Col>
    </Row>
    <Row class="expand-row" style="margin-top: 10px;">
      <Col span="2">结束时间:</Col>
      <Col span="22">{{ $formatTime(row.submitTime, 'yyyy-MM-dd hh:mm') }}</Col>
    </Row>
  </div>
</template>

<script>
export default {
  name: 'exspanRow',
  props: {
    row: Object
  }
}
</script>

<style lang="less" scoped></style>
