import { render, staticRenderFns } from "./CommonPage.vue?vue&type=template&id=5d17fe93&scoped=true&"
import script from "./CommonPage.vue?vue&type=script&lang=js&"
export * from "./CommonPage.vue?vue&type=script&lang=js&"
import style0 from "./CommonPage.vue?vue&type=style&index=0&id=5d17fe93&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d17fe93",
  null
  
)

export default component.exports